import './styles/Main.scss';
import React, {useState} from 'react';
import NFTMart from "./assets/NFTMart.png";
import selfie from "./assets/selfie.jpg";
import HETools from "./assets/HETools.png";
import HiveLink from "./assets/HiveLink.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Language(props) {
    let language = Object.keys(props).join(" ");
    language = language.replace("dot", ".");
    language = language.replace("sharp", "#");
    return <span className={"language-" + language.toLowerCase()}>{`"${language}"`}</span>;
}

function ImageDisplay(props) {
    return <div className={"image-display " + (props.className || "")} style={{backgroundImage: `url(${props.bgImage})`}}>
        {props.children}
    </div>;
}

function App() {
    let [imageClasses, setImageClasses] = useState(["photo"]);

    return (
        <React.Fragment>
            <nav>
                <a id="homepage-link" href={"/"}>Conor <span>Howland</span></a>
                <div className={"spacer"} />
                <a href="https://github.com/Snaddyvitch-Dispenser"><FontAwesomeIcon icon={faGithub} color={"#fff"} /></a>
                <a href="https://linkedin.com/in/conor-howland"><FontAwesomeIcon icon={faLinkedin} color={"#fff"} /></a>
                <a href="mailto:hi@conorhow.land"><FontAwesomeIcon icon={faEnvelope} color={"#fff"} /></a>
            </nav>

            <section className="welcome">
                <div>
                    <div className="text">
                        <h1>Hello,</h1>
                        <p>I'm Conor Howland,</p>
                        <p>a software and website developer.</p>
                    </div>
                </div>
                <div className="photo-me"><img alt="Me" onLoad={() => setImageClasses(["photo", "loaded"])}
                                               className={imageClasses.join(" ")} src={selfie} onDrag={() => false}/>
                </div>
            </section>

            <section className="knowledge">
                <h1>I have experience with a wide array of languages</h1>
                <p className="languages">let languages = [</p>
                <p className="languages indented-1">[ <Language React />, <Language HTML />, <Language CSS />, <Language PHP />, <Language JavaScript />, <Language ASPdotNET />, <Language Csharp />, <Language SCSS /> ], <span className="comment">// Web</span></p>
                <p className="languages indented-1">[ <Language Python />, <Language Node />, <Language VBdotNET /> ] <span className="comment">// Software</span></p>
                <p className="languages">];</p>
                <p className="languages comment">// And I'm always willing to learn more.</p>

                <h1 className="with-margin">And topics<span className="grey">.md</span></h1>
                <p className="languages comment">- Blockchain</p>
                <p className="languages comment">- Interactive Web Apps</p>
                <p className="languages comment">- SQL Databases</p>
                <p className="languages comment">- Frontend & Backend Development</p>
            </section>

            <section className="projects">
                <h1>Projects</h1>
                <ImageDisplay className="project-nftmart" bgImage={NFTMart} blur={1} bgImageAlt="NFTMart" strength={600}>
                    <h1><a href="https://nftm.art">NFTMart</a></h1>
                    <p>A marketplace for NFTs powered by the <a href="https://hive.io" rel="nofollow noopener">HIVE</a> blockchain. I maintain and add features to the frontend of the website.</p>
                </ImageDisplay>
                <ImageDisplay className="project-hetools" bgImage={HETools} bgImageAlt="Hive Engine Tools">
                    <h1><a href="https://engine.hive.uno">Hive Engine Tools</a></h1>
                    <p>A free toolset for <a href={"https://hive-engine.com"} rel="nofollow noopener">Hive-Engine</a>.</p>
                    <p>Allows you to view market orders and delegations.</p>
                </ImageDisplay>
                <ImageDisplay className="project-hivelink" bgImage={HiveLink} bgImageAlt="Hive Link">
                    <h1><a href="https://hivel.ink">HiveLink</a></h1>
                    <p>An easy to use frontend picker for the Hive Blockchain</p>
                </ImageDisplay>
                <p className={"link-text"}>See More on my <a href="https://github.com/Snaddyvitch-Dispenser" rel="nofollow noopener">GitHub</a></p>
            </section>
            <section className="contact">
                <h1>Want to talk?</h1>
                <p>Send an email to <a href="mailto:hi@conorhow.land">hi@conorhow.land</a> and I'll get back to you as soon as possible!</p>
            </section>
        </React.Fragment>
    );
}

export default App;
